import './SearchBar.scss'
import fetch from 'cross-fetch'
import React, { useEffect, useState } from 'react'
import { TextField, CircularProgress } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useHistory, useLocation } from 'react-router-dom'

function SearchBar({ selectedWord, setSelectedWord }) {
    const [open, setOpen] = useState(false)
    const [defs, setDefs] = useState([])
    const [value, setValue] = useState('')
    const [loading, setLoading] = useState(false)
    const [inputValue, setInputValue] = useState('');
    var searchHis = [];
    const history = useHistory()
    let location = useLocation();
    const redirect = (word) => {
        console.log("WORD WE ARE SEACHING FOR:", word)
        word.titre ? history.push('/search/results?titre=' + word.titre_brut) : history.push('/search?titre=' + word)
        setSelectedWord(word)
    }

    function handleSearch(item) {
        console.log(location.pathname)
        if (item != null) {
            setSelectedWord(item);
            if (localStorage.getItem("history2")) {
                searchHis = JSON.parse(localStorage.getItem("history2"));
            }
            redirect(item);
            searchHis.push(item.titre || item)
            console.log(searchHis)
            localStorage.setItem("history2", JSON.stringify(searchHis));
        }
    }

    const setItemOnLocalStorage = () => {

    }
    useEffect(() => {
        if (inputValue) {
            ; (async () => {
                const response = await fetch(
                    `http://51.68.80.15:8000/api/elastic/search/?titre=${inputValue}`
                )
                const defs = await response.json()
                if (inputValue != '') {
                    setDefs(defs)
                }
            })()
        }
    }, [inputValue])


    console.log(">>>>>>>>.", inputValue)
    // React.useEffect(() => {
    //     if (!open) {
    //         setDefs([])
    //     }
    // }, [open])


    return (
        <>
            <div className=' m-auto' style={{ width: '40%' }}>
                <Autocomplete
                    open={open}
                    onOpen={() => {
                        setOpen(true)
                    }}
                    onClose={() => {
                        setOpen(false)
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                    value={value}
                    freeSolo={true}
                    onChange={(event, newval) => handleSearch(newval)}
                    getOptionSelected={(def, value) => (def.titre == value.titre)}
                    getOptionLabel={(def) => def.titre_brut || ""}
                    //getOptionLabel={(def) => console.log(def)}
                    options={defs}
                    loading={loading}
                    noOptionsText='Aucune définition'
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Saisir le titre de la définition :"
                            fullWidth
                            className='bg-white'
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? (
                                            <CircularProgress
                                                color="inherit"
                                                size={20}
                                            />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </div>
        </>
    );

}

export default SearchBar;