const images = {
    hall : require('./hall.png'),
    logo : require('./logo.png'),
    editorial: require('./editorial.jpg'),
    searchimg : require('./searchimg.jpg'),
    livre : require('./livre.jpg'),
    logoDicMaxi: require('./logoDicMaxi.png'),
    logoDicMini : require('./logoDicMini.png')
}

export default images