import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,

} from "react-router-dom";
import Search from './Pages/Search/Search';
import LandingPage from './Pages/Landing-Page/LandingPage';
import SearchResults from './Pages/Search/SearchResults';
import RedirectedSearchResults from './Pages/Search/RedirectedSearchResults';

function App() {
  return (
    <div className="App">
        <Router>
        <Switch>
          <Route path="/" exact>
          <LandingPage />
          </Route>
          <Route path="/search" exact>
            <Search />
          </Route>
          <Route path="/index.php" exact>
            <RedirectedSearchResults />
          </Route>
          <Route path="/search/results" exact>
            <SearchResults />
          </Route>
        </Switch>
    </Router>
    </div>
  );
}

export default App;