import React,{useEffect, useRef} from 'react';
import "./MainTextField.scss"
import FontSizeChanger from 'react-font-size-changer';
import ReactToPrint, {useReactToPrint} from 'react-to-print'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import images from '../../assets/images/images'
import {MapTitlesToKeys,titleProps,printStyle} from '../../Utils/utils'
import { useHistory } from 'react-router-dom';

function MainField({selectedWord,setSelectedWord}) {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      pageStyle: printStyle,
    });
    const history = useHistory()

    const fetchMyWord = (myWord) =>{
        fetch('http://51.68.80.15:8000/api/elastic/search/?id='+myWord.id)
            .then(response => response.json())
            .then(data => (
                setSelectedWord(data[0]),
                history.push('/search/results?titre='+myWord.titre)
            ))
            .catch((error) => {console.log(error.message ,myWord ,selectedWord)})
    }
    // console.log("MAIN",selectedWord)
    return (
    <div className ="mainTextFieldContainer">
    <div className= 'flexFontAndMain'>
                <div className = 'actionBtns'>
                <FontSizeChanger
                    targets={['.fontTarget']}
                    options={{
                        stepSize: 2,
                        range: 3
                    }}
                    customButtons={{
                        up: <span style={{'fontSize': '30px'}}>A</span>,
                        down: <span style={{'fontSize': '16px'}}>A</span>,
                    }}          
                />
                <button onClick={handlePrint} className='printBtn'>
                    <FontAwesomeIcon icon = {faPrint} color="purple"/>
                </button> 
                </div>
               <div className="mainTextField" ref = {componentRef} >
                <img src={images.logo.default} className='printLogo'/>
               <div className= "title">
                           {Object.keys(titleProps).map(key => {
                               if(key === titleProps[key] && selectedWord[key] != ""){
                                    if(key == 'titre_brut'){
                                        return <span dangerouslySetInnerHTML={{__html: selectedWord['titre_brut'] + " "}}></span> 
                                    }
                                    if(key == 's_cat'){
                                        return <> <span style={{fontWeight: 'normal', fontSize: '17px'}} dangerouslySetInnerHTML={{__html: selectedWord[key]}}></span><br/></>
                                    }
                                    return <span  style={{fontWeight: 'normal', fontSize: "1em" , marginRight:'10px'}} dangerouslySetInnerHTML={{__html: selectedWord[key]}}></span>
                               }
                           })}
                   </div>
                   <div className="mainArea fontTarget">
                       {Object.keys(MapTitlesToKeys).map(key => {
                        if(key ==='homonyme' && selectedWord[key] == undefined)return;
                        if(MapTitlesToKeys[key]){
                           if(key === MapTitlesToKeys[key].name && selectedWord[key] != ""){
                               if(MapTitlesToKeys[key].type === 'normal' && MapTitlesToKeys[key].type){
                                    return <div style={{marginBottom: '1rem'}}>
                                            {MapTitlesToKeys[key].name === 'edition'
                                             ? 
                                            <span style={{fontStyle: 'italic'}} dangerouslySetInnerHTML={{__html:MapTitlesToKeys[key].label + " "}}></span>
                                             : 
                                            <span style={{fontStyle: 'italic'}} dangerouslySetInnerHTML={{__html:MapTitlesToKeys[key].label + " : " }}></span>}<span dangerouslySetInnerHTML={{__html: selectedWord[key]}}></span>
                                        </div>
                               }else if(MapTitlesToKeys[key].type === 'definition'){
                                    return <div style={{marginBottom: '1rem'}}>
                                                {
                                                    selectedWord[key].map(
                                                        (def,index)=>{      
                                                            return<> {  selectedWord[key].length > 1 && <h6>{[index+1] + '.'} </h6> }<p dangerouslySetInnerHTML={{__html: def.definition }}></p>
                                                            {
                                                                def.commentaire && 
                                                                <div style={{marginLeft: '25px'}}><h6 style={{fontStyle: 'italic'}} dangerouslySetInnerHTML={{__html: def.commentaire }}></h6></div>
                                                            }
                                                            </>
                                                        }
                                                    )
                                                }
                                            </div>
                               }
                               else if (MapTitlesToKeys[key].type === 'voir'){
                                //    console.log(selectedWord && selectedWord[key].length)
                                    return <>
                                    <div style={{marginBottom: '1rem'}}>
                                                <span style={{fontStyle: 'italic'}}>{MapTitlesToKeys[key].label && MapTitlesToKeys[key].label +":"}</span>    
                                                <span>{selectedWord[key] && selectedWord[key].map((e, index, array) => <span><a href= '#'  onClick={()=> fetchMyWord(e) }>{e.titre}</a> {(array[index+1] ? ", " : "")}</span>)}</span>
                                                {/* <span>{selectedWord[key] && Array.prototype.map.call(selectedWord[key], function(item) { return ( <a href= '#'  onClick={()=> fetchMyWord(item) }>{item.titre}</a> ) }).join(", ")}</span> */}
                                            </div></>
                               }
                           }
                        }
                        })}
                   </div>
              </div>
            </div>
        </div>
    );
}

export default MainField