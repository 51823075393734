import React,{useState} from 'react';
import SearchBar from '../../Components/SearchBar/SearchBar';
import Footer from '../../Components/Footer/Footer'
import './LandingPage.scss'
import images from '../../assets/images/images';
import { slideInLeft , slideInUp ,slideInDown } from 'react-animations';
import Radium, {StyleRoot} from 'radium';


function LandingPage(props) {
    const [selectedWord,setSelectedWord] = useState();

    const styles ={
        headAnimation: {
            animation: 'x 1s',
            animationName: Radium.keyframes(slideInDown, 'bounce')
        },
        SearchBarAnimation:{
            animation: 'x 1s',
            animationName: Radium.keyframes(slideInLeft, 'bounce')
        },
        mainFieldAnimation:{
            animation: 'x 1s',
            animationName: Radium.keyframes(slideInUp, 'bounce')
        }
    }
    return (
        <StyleRoot>
        <div className = 'parent'>
            <div className ="mainLanding">
                <div className="headerDiv" style={styles.headAnimation}>
                    <img src={images.logo.default} alt="" className ='img-responsive'/>
                    <ul>
                        <li><a>Contact</a></li>
                        <li>Aide</li>
                    </ul>
                </div>
                <div className = 'landingContent' style={styles.SearchBarAnimation}>
                    <SearchBar setSelectedWord={setSelectedWord} selectedWord= {selectedWord}/>
                </div>
            </div>
            <div className = "editorialContainer" style={styles.mainFieldAnimation}>
                <div className="textDiv">
                    <h2>ÉDITORIAL</h2>
                    <p>                
                    Le dictionnaire de l’Académie nationale de médecine en ligne rassemble en permanence l’ensemble du vocabulaire médical dont il doit suivre l’enrichissement lié à l’extraordinaire évolution de la médecine et de ses moyens d’expression au cours des dernières décennies et en ce début du XXIe siècle. <br/><br/>

                        Seul le support informatique permet une telle ambition de mise à jour régulière et de diffusion à un vaste public de la pensée médicale française.<br/><br/>

                        Chaque terme, chaque concept est accessible à la fois par son entrée en français et par une entrée en anglais lorsqu’elle existe. Chaque article complet comporte une brève définition lexicologique, un commentaire plus encyclopédique, une référence à l’auteur de la publication princeps et la date de celle-ci, l’étymologie des mots clés, le ou les synonymes ou antonymes et un renvoi à d’autres concepts voisins accessibles dans le dictionnaire par simple clic.<br/><br/>

                        Cette œuvre est la poursuite de l’édition initiale de quinze volumes, conduite à l’instigation de J.Ch. Sournia, entre 1997 et 2007, essentiellement par le Conseil international de la langue française (CILF). Cette édition papier était une œuvre collective d’académiciens et de consultants menée sous la direction des responsables de volumes spécialisés dont il importe de rappeler les noms à défaut de pouvoir citer ici tous leurs collaborateurs : P. Ambroise-Thomas, L. Auquier, J.P. Benhamou, D. Biquet, P. Blondeau, S. Bonfils, M. Bourel, M. Cara, J. Cauchoix, C. Chatelain, J. Civatte, B. Debesse, J. Frottier, P. Juillet, H. Laccoureye, M. Legrain, J. Milliez, G. Pallardy, P. Pialloux, P. Polonovski, Y. Pouliquen, J. Rochemaure.<br/>

                        « On fait, défait, refait ce beau dictionnaire, qui toujours très bien fait, sera toujours à faire »  (Lebrun Pindare)<br/><br/>

                        Le « comité de rédaction du dictionnaire » * de l’Académie nationale de médecine, conscient de l’ampleur de la tâche technique et de la responsabilité scientifique qu’elle représente, .se réunit régulièrement deux fois par mois pour la mise à jour systématique des définitions et l’introduction de nouvelles entrées. Ses compétences sont élargies par la collaboration de « consultants »**, membres de l’Académie, qui   ne peuvent se joindre au comité. Chaque entrée est actualisée, rédigée avec le souci de la clarté et de la compréhension par tous (médecins, paramédicaux et société civile). Les définitions obsolètes ne sont pas supprimées en raison de leur intérêt historique et de l’indication des concepts actuels. Enfin se pose parfois la question d’introduire des notions qui ne sont pas directement, strictement médicales, qui relèvent des mathématiques, de la physique et du droit, de plus en plus souvent liés à la pratique professionnelle.<br/><br/>

                        L’édition des définitions est réalisée dès leur validation. Elle offre actuellement au lecteur plus de 60 000 définitions.                    

                    </p>
                </div>
                <div className="imageDiv">
                        <img src = {images.hall.default}  className ='carouselImage img-responsive'/>
                        <p> © Bibliothèque de l’Académie Nationale de Médecine, photographie Philippe Fuzeau </p>
                </div>

            </div>
            <Footer />
        </div>
        </StyleRoot>
    );
}

export default LandingPage;