import React, { useEffect, useState } from 'react';
import '../../cards.scss'
import ListGroup from 'react-bootstrap/ListGroup'
import { useHistory } from 'react-router-dom';

function Voisinage({selectedWord,setSelectedWord}) {
    const [voisinage,setVoisinage] = useState([]);
    var hist = [];
    useEffect(() =>{
        fetch('http://51.68.80.15:8000/api/elastic/voisinage/?id='+selectedWord.id)
            .then(res => res.json())
            .then(data =>{
console.log('==========================>', data);
 setVoisinage(data)
}
)
    },[selectedWord])
    const history = useHistory()
    const clickedVoisinage = (word) =>{
        fetch('http://51.68.80.15:8000/api/elastic/search/?id='+word.id)
        .then(res => res.json())
        .then(data =>  (setSelectedWord(data[0]) , history.push('/search/results?titre='+word.titre)))
        if(localStorage.getItem('history2')){
            hist = JSON.parse(localStorage.getItem("history2"));
        }
        // let hist = new Array((localStorage.getItem('history')));
        hist.push(word.titre)
        localStorage.setItem("history2", JSON.stringify(hist));
        // localStorage.setItem('history', hist);
    }


    return (
        <div className= 'cardContainer'>
            <div className = 'head'>
                <p>Voisinage Alphabetique</p>
            </div>
            <ListGroup>
                {
                    voisinage && voisinage.map(
                        (word) => {
                            return <ListGroup.Item className='cardsLinks' onClick ={() => clickedVoisinage(word)}>{word.titre}</ListGroup.Item>
                        }
                    )
                }
            </ListGroup>
        </div>
    );
}

export default Voisinage;
