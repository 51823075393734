import React, { useEffect , useState } from 'react';
import '../../cards.scss'
import ListGroup from 'react-bootstrap/ListGroup'
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';


function History({selectedWord,setSelectedWord}) {
    const [historyFromLocalStorage, sethistoryFromLocalStorage] = useState(JSON.parse(localStorage.getItem('history2')));
    useEffect(()=>{
        sethistoryFromLocalStorage(JSON.parse(localStorage.getItem('history2')))
    },[localStorage.getItem('history2')])
    let history = localStorage.getItem('history') || [];
    if(history.length != 0){
        history = history.split(",").filter(h => h)
    }
    let uniqueArray = history.filter(function(item, pos) {
        return history.indexOf(item) == pos;
    })

    if(uniqueArray.length > 0){
        if(uniqueArray.length > 5){
            uniqueArray.shift()
        }
    }
    const uHistory = useHistory()
    const selected = (word) =>{
        fetch('http://51.68.80.15:8000/api/elastic/search/?titre='+word)
        .then(res => res.json())
        .then(data => (setSelectedWord(data.find(el => el.titre === word)),uHistory.push('/search/results?titre='+word) ))
    }
    return (
        <div className= 'cardContainer'>
            <div className = 'head'>
                <p>Historique de Consultation</p>
            </div>   
              <ListGroup >
                  {
                    historyFromLocalStorage && 
                    historyFromLocalStorage.slice(Math.max(historyFromLocalStorage.length - 10, 0)).reverse().map((word,key)=>{ 
                        // if(word == null) return null;          
                        return <ListGroup.Item key = {key} onClick ={() => selected(word)} className='cardsLinks' dangerouslySetInnerHTML={{__html: word}}>
                            
                        </ListGroup.Item>
                    })
                  }
            </ListGroup>
        </div>
    );
}

export default History;