
import React, { useEffect, useState } from 'react';
import '../../cards.scss'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useHistory } from 'react-router-dom';

function Auteur({selectedWord,setSelectedWord}) {
    const [author,setAuthor] = useState(false)
    const [modalShow, setModalShow] = React.useState(false);
    let history = useHistory();
    var lien = []
    const lienVersDictionnaire = (id,titre) => {
      fetch("http://51.68.80.15:8000/api/elastic/voisinage/?id="+id)
      .then(res => res.json())
      .then(data => (
        setSelectedWord(data[0]),
        history.push('/search/results?titre='+titre)
      ))
      if(localStorage.getItem('history2')){
        lien = JSON.parse(localStorage.getItem("history2"));
      }
      lien.push(titre)
      localStorage.setItem("history2", JSON.stringify(lien));
      // localStorage.setItem('history', lien);
    }

    function AuteurModal(props) {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {console.log("PROPS",props)}
                <p dangerouslySetInnerHTML={{__html: props.data.Nom}}></p>
                {props.data && props.data.Naissance && <span style={{fontSize: '17px'}} dangerouslySetInnerHTML={{__html: " " + props.data.Naissance}}></span>}
                {props.data && props.data.deces != 0 && <span style={{fontSize: '17px'}} dangerouslySetInnerHTML={{__html: "-" + props.data.deces}}></span>}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h6>Biographie</h6>
                <p>{props.data && props.data.biographie}</p>
              <h6>Bibliographie</h6>
                {props.data && props.data.bibliographie.map((para) =>{
                    return <p>{para}</p>
                })}
              <h6>Liens Vers le Dictionnaire</h6>
              {props.data.liens && props.data.liens.map((lien) => {
                  return <a href='#' onClick= {()=> {lienVersDictionnaire(lien.id,lien.titre);props.onHide()}}>{lien.titre + ",\t"}</a>
              })}
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={props.onHide} style={{backgroundColor: '#a2578e',border: 'none'}}>Fermer</Button>
            </Modal.Footer>
          </Modal>
        );
      }

    const showMore = async (auteur) =>{
      setModalShow(true)
        await fetch('http://51.68.80.15:8000/api/elastic/auteur/?id='+auteur.id)
            .then(res => res.json())
            .then((data) => {console.log("author data",data);setAuthor(data[0])})
            .catch((error) => console.log("author error",error.message))
    }

    return (
        <div className ="cardContainer">
        <div>
            <div className = 'head'>
                <p>Auteur</p>
            </div>
        </div>
        <div style={{padding: '1rem'}}>
            {selectedWord.auteurs.map((auteur) =><> <div><a href="#" onClick={()=> {showMore(auteur)}}>{auteur.nom}</a>, {auteur.description}</div>
            </>
            )}
            <AuteurModal 
                show = {modalShow}
                onHide = {()=>setModalShow(false)}
                data = {author}
            />
        </div>
    </div>
    );
}

export default Auteur;