
import { Box, Card, CardActions, CardContent, Typography, Button, IconButton, Tooltip, Icon, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import './Results.scss'
import images from '../../assets/images/images';

export default function Results(props) {
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0)
  };
  const [suggestions, setSuggestions] = useState([])
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  let history = useHistory()

  console.log('SUGGESTIONS', suggestions.results)

  useEffect(() => {
    fetch("http://51.68.80.15:8000/api/elastic/search/?titre=" + query.get('titre') + '&mode=mini&max_results=20&page=' + (page - 1) + '&page_size=20')
      .then(res => res.json())
      .then(data => (setSuggestions(data)))
  }, [query.get('titre'), page])

  const handleRedirect = (titre) => {
    history.push('search/results?titre=' + titre)
  }
  const Ele = ({ titre, definition, edition }) => {
    return (
      <div className='flex-row' style={{ marginBottom: '2%' }}>
        <div className="flex items-center">
          {/* <Icon>group</Icon> */}
          <div className=" flex-column">
            <a className="linkTitle" onClick={() => handleRedirect(titre)} dangerouslySetInnerHTML={{ __html: titre }}>
            </a>
            <br />
            <small className="elepsisTxt" style={{ fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: definition ? definition : '' }}></small>
            <br />
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className='resultsContainer'>
      <h5 className='resultHeadingTxt'>Résultats de recherche pour: <span className='searchedWord'>"{query.get('titre')}"</span></h5>

      <div style={{ paddingInline: '16%', width: '90%' }}>
        {
          suggestions.results && suggestions.results.length != 0 ?
            <>
              {suggestions.results.map(item =>
                <Ele titre={item.titre} definition={item.definition} edition={item.edition} />)
              }
              <hr />
              {suggestions.count > 20 &&
                <Stack>
                  <Typography>Page: {page}</Typography>
                  <Pagination count={Math.floor(suggestions.count / 20)} page={page} onChange={handleChange} />
                </Stack>
              }
            </>
            : <small className="text-muted"> Désolé, nous n'avons pas pu identifier la definition que vous recherchez.</small>
        }
      </div>

    </div>
  )
}
