import React from 'react';
import './Header.scss'
import images from '../../assets/images/images'
import { useHistory} from 'react-router';

function Header(props) {
    const history = useHistory();
    const redirect = () => {
        history.push('/')
      }
    return (
        <div className = "headerContainer">
                <img src={images.logo.default} alt="" className= 'logo img-responsive' onClick = {redirect}/>
            <div>
                <ul className ="headLinks">
                    <li><a href = "mailto:dictionnaire@academie-medecine.fr">Contact</a></li>
                    <li>Aide</li>
                </ul>
            </div>
        </div>
    );
}

export default Header;