import React from 'react';
import './Footer.scss'

function Footer(props) {
    return (
        <div className= "footerContainer">
            <p>@l'Académie de Médecine 2021</p>
        </div>
    );
}

export default Footer;