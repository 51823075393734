import React, {useState,useHistory, useEffect} from 'react';
import Header from '../../Components/Header/Header';
import SearchBar from '../../Components/SearchBar/SearchBar';
import MaintextField from '../../Components/MainTextField/MaintextField';
import './Search.scss'
import Auteur from '../../Components/Auteur/Auteur';
import History from '../../Components/History/History';
import Voisinage from '../../Components/Voisinage/Voisinage'
import { zoomIn } from 'react-animations';
import Radium, {StyleRoot} from 'radium';
import {
  BrowserRouter as Router,
  Switch,
  Route,

} from "react-router-dom";
import Results from '../../Components/Results/Results';



function Search(props) {
    const [selectedWord,setSelectedWord] = useState()
    const [, updateState] = React.useState();
    const styles = {
        zoomInMain: {
          animation: 'x 0.75s',
          animationName: Radium.keyframes(zoomIn, 'bounce')
        },
        zoomInHistory: {
            animation: 'x 1.5s',
            animationName: Radium.keyframes(zoomIn, 'bounce')
          },
          zoomInVoisinage: {
            animation: 'x 1.25s',
            animationName: Radium.keyframes(zoomIn, 'bounce')
          },
          zoomInAuteur: {
            animation: 'x 0.75s',
            animationName: Radium.keyframes(zoomIn, 'bounce')
          },
      }

      let se = localStorage.getItem('history') || [];
      if(se.length !== 0){
          se = se.split(",").filter(h => h)
      }
      let selectedMot = se.slice(-1)[0]

      console.log("SELECTED WORD INSIDE SEARCH PAGE", selectedWord)
      useEffect(() => {
        fetch("http://51.68.80.15:8000/api/elastic/search/?titre="+selectedMot)
        .then(res => res.json())
        .then(data =>setSelectedWord(data.find((el) => el.titre === selectedMot)))
      },[selectedMot])

    return (
        <StyleRoot>
        <div className ="mainContainer">
        <div className ="contentDiv">
            <Header />
            <div className = 'searchThings'>
                <SearchBar selectedWord={selectedWord} setSelectedWord ={setSelectedWord}/>
            </div>
            <Results></Results>
        </div>
        </div>
        </StyleRoot>
    );
}

export default Search;