import React, {useState,useHistory, useEffect} from 'react';
import Header from '../../Components/Header/Header';
import SearchBar from '../../Components/SearchBar/SearchBar';
import MaintextField from '../../Components/MainTextField/MaintextField';
import './Search.scss'
import Auteur from '../../Components/Auteur/Auteur';
import History from '../../Components/History/History';
import Voisinage from '../../Components/Voisinage/Voisinage'
import {StyleRoot} from 'radium';
import {useLocation } from 'react-router-dom';
import { searchResultStyles } from '../../Utils/utils';

function SearchResults(props) {
    const [selectedWord,setSelectedWord] = useState()
      function useQuery() {
        return new URLSearchParams(useLocation().search);
      }
      let query = useQuery();
      useEffect(() => {
        fetch("http://51.68.80.15:8000/api/elastic/search/?titre="+query.get('q'))
        .then(res => res.json())
        .then(data =>(console.log("DATA I WANT",data),setSelectedWord(data[0])))
      },[query.get('q')])

    return (
        <StyleRoot>
        <div className ="mainContainer">
        <div className ="contentDiv">
            <Header />
            <div className = 'searchThings'>
                <SearchBar selectedWord={selectedWord} setSelectedWord ={setSelectedWord}/>
            </div>
            {
                selectedWord && 
                            <div className='mainSearchFlex'>
                            <div className="mainTextFieldDiv" style={searchResultStyles.zoomInMain}>
                           <MaintextField selectedWord = {selectedWord} setSelectedWord = {setSelectedWord}/>
                           </div>
                                <div className = "utilsContainer">
                                    <div className='auteur util' style={searchResultStyles.zoomInAuteur}>
                                    {
                                     selectedWord.auteurs && selectedWord.auteurs.length !=0 && 
                                        <Auteur selectedWord = {selectedWord} setSelectedWord = {setSelectedWord}/>
                                     }
                                    </div>
                                        <div className = 'voisinage util' style={searchResultStyles.zoomInVoisinage}>{selectedWord &&<Voisinage selectedWord ={selectedWord} setSelectedWord={setSelectedWord}/>} </div>
                                        <div className= 'history util' style={searchResultStyles.zoomInHistory}><History selectedWord={selectedWord} setSelectedWord = {setSelectedWord}/></div>
                                </div>
                            </div>
            }
        </div>
        </div>
        </StyleRoot>
    );
}

export default SearchResults;