import { zoomIn } from 'react-animations';
import Radium from 'radium';

export const MapTitlesToKeys = {
    terminologia_anatomica: {
        name:'terminologia_anatomica', 
        label: 'Terminologie',
        type:'normal'
    },
    traduction_en: {
        name:'traduction_en', 
        label: 'Anglais',
        type:'normal'
    },
    definition: {
        name:'definition',
        label: '',
        type:'definition'
    },
  
    etymologie: {
        name:"etymologie", 
        label:'Etymologie',
        type:'normal'
    },
    synonyme: {
        name:'synonyme',
        label: 'Synonymes',
        type:'normal'
    },
    
    antonyme: {
        name:'antonyme',
        label: 'Antonymes', 
        type:'normal'
    },
    homonyme: {
        name:'homonyme',
        label: 'Homonyme', 
        type:'normal'
    },
    symbole: {
        name:'symbole',
        label: 'Symbole', 
        type:'normal'
    },
    abreviation: {
        name:'abreviation',
        label: 'Abreviation',
        type:'normal'
    },
    references: {
        name:'references',
        label: 'Références ',
        type:'normal'
    },
    voir: {
        name:'voir',
        label: 'Cf.',
        type:'voir'
    },
    voir_aussi: {
        name:'voir_aussi',
        label: '',
        type:'voir'
    },
    domaines: {
        name:'domaines',
        label: 'Domaines',
        type:'normal'
    },
    edition: {
        name:'edition',
        label: 'Edition',
        type:'normal'
    },
}

export const titleProps = {
    titre: 'titre',
    s_cat: 's_cat',
    sigle: 'sigle',
    sigle_2 : 'sigle_2'
}

export const printStyle = `
.mainTextField{
    padding-inline : 100px
}

.printLogo{
    margin-bottom: 50px;
    margin-left: -20px
}

.title{
    text-align: center;
    font-size: 1.5rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #a2578e;
    font-weight: 500;
}

.mainArea{
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.badge{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif, 'Times New Roman', Times, serif;    
}
`

export const searchResultStyles ={
        zoomInMain: {
          animation: 'x 0.75s',
          animationName: Radium.keyframes(zoomIn, 'bounce')
        },
        zoomInHistory: {
            animation: 'x 1.5s',
            animationName: Radium.keyframes(zoomIn, 'bounce')
          },
          zoomInVoisinage: {
            animation: 'x 1.25s',
            animationName: Radium.keyframes(zoomIn, 'bounce')
          },
          zoomInAuteur: {
            animation: 'x 0.75s',
            animationName: Radium.keyframes(zoomIn, 'bounce')
          },
}